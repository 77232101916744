import React from "react"
import { useStaticQuery, graphql, } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => {

  const { contentfulHomePage } = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        childContentfulHomePageWelcomeTextTextNode {
          welcomeText
        }
        pageName
        background {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `);
  return (
    <Layout queryData = { contentfulHomePage }>
      <SEO title="Home" />
      <div id="contentElem" >
        <h1>{ contentfulHomePage.pageName }</h1>
        <p style={{textAlign:'justify', textJustify:'inter-word'}}>
          { contentfulHomePage.childContentfulHomePageWelcomeTextTextNode.welcomeText }
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage;
